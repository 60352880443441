// CSS Reset, comment out if not required or using a different module
@import "~minireset.css/minireset";
/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/foundation/_base";
@import "./assets/scss/foundation/_destyle";
@import "./assets/scss/foundation/variable";

input[type="text"],
input[type="email"],
input[type="number"],
input[type="date"],
input[type="password"] {
  cursor: text;
  // transition: border 0.2s ease-in-out, background 0.2s ease-in-out,
  //   box-shadow 0.2s ease-in-out;
}

::placeholder {
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  color: #aea2a2;
  @media screen and (max-width: #{map-get($BREAKPOINTS, 'tablet')}) {
    font-size: 16px;
    line-height: 23px;
  }
}

textarea {
  cursor: text;
  transition: border 0.2s ease-in-out, background 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
}

.l-error {
  height: 28px;
}

.error-messege {
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  font-family: "Zen_Maru_Gothic variant1", Tofu;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #ef2d44;
  @media screen and (max-width: #{map-get($BREAKPOINTS, 'tablet')}) {
    font-size: 13px;
    line-height: 19px;
  }
}
.c-error {
  background: rgba(242, 172, 197, 0.7);
  border: 1px solid #ef2d44;
  border-radius: 5px;
}
.input-text-title {
  font-size: 12px;
  font-weight: 900;
}

.input-space {
  margin-top: 1rem;
}

select {
  padding: 0px 0px 0px 10px;
  width: 100%;
  height: 40px;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  background-color: #fff;
  transition: border 0.2s ease-in-out, background 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  cursor: pointer;
}

html,
body {
  height: 100%;
  font-family: "Zen_Maru_Gothic variant1", Tofu;
  font-style: normal;
}

@import "~igniteui-angular/lib/core/styles/themes/index";
// Uncomment the following lines if you want to add a custom palette:
// $primary: #731963 !default;
// $secondary: #ce5712 !default;
// $app-palette: igx-palette($primary, $secondary);

/* autoprefixer grid: on */

@include igx-core();
@include igx-theme($default-palette);

/**
 * breakpoints
 */
$BREAKPOINTS: (
  "mobile": 320px,
  "tablet": 560px,
  "desktop": 960px,
);

/**
 * colors
 */

$RED: #d50000;
$LIGHT_RED: #ff0000;

$LIGHT: #ffffff;
$SUPER_LIGHT: #f5f5f5;

$BLUE: #47bcc6;
$BLUE_DARK: #48bcc6;

$YELLOW: #fff000;

$BLACK_LIGHT: #222222;
$BLACK: #000000;

$GRAY: #d5d5d5;

$GRAY_DARK: #707070;

$GRAY_LIGHT: #e2e2e2;
$GRAY_SUPER_LIGHT: #cbcbcb;

/**
 * frame size
 */
/** desktop */
$OUTER_FRAME_WIDTH_DESKTOP: 1040px;
$INNER_FRAME_WIDTH_DESKTOP: 888px;

/** mobile */
$OUTER_FRAME_WIDTH_MOBILE: 320px;
$INNER_FRAME_WIDTH_MOBILE: 304px;
